import axios from "axios";

export const adminIndicatorsApi = () => {
  return axios.get(`/admin/home/indicators`);
};
export const partnerAchievementsApi = () => {
  return axios.get(`/client/home/my-achievements`);
};
export const totalAdminRequestsApi = () => {
  return axios.get(`/admin/home/get-total-requests-number`);
};
export const pendingRequestsApi = () => {
  return axios.get(`/admin/payment/requests/sidebar/pending`);
};
export function partnerEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/client/request/list`, {
    params: { search: searchTerm, page },
  });
}
export const partnerProfileApi = (id) => {
  return axios.get(`/admin/partners-list/profile/${id}`);
};
export const viewPaymentDetailsApi = (id) => {
  return axios.get(`/admin/payment/requests/details/${id}`);
};
export const viewAdminCoursesApi = () => {
  return axios.get(`/admin/reports/courses/view`);
};

// Taswia
export const taswiaListApi = (
  searchTerm = "",
  startDate = "",
  endDate = "",
  page = 1
) => {
  return axios.get("/admin/taswia/list", {
    params: {
      SelectionDateStart: startDate,
      SelectionDateEnd: endDate,
      search: searchTerm,
      page: page,
    },
  });
};

export const canCreateTaswiaApi = () => {
  return axios.get("/admin/taswia/can-create");
};

export const lastTaswiaEndDateApi = () => {
  return axios.get("/admin/taswia/last-taswia-end-date");
};

export const createTaswiaApi = (endDate, confirm) => {
  return axios.post("/admin/taswia/create", null, {
    params: {
      end_date: endDate,
      confirm: confirm,
    },
  });
};

export const listUnsettledClientsApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/unsettled-clients/${taswiaId}`, {
    params: { page },
  });
};
export const listSettledClientsApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/settled-clients/${taswiaId}`, {
    params: { page },
  });
};

export const viewClientToSettleApi = (taswiaId, partnerId) => {
  return axios.get(
    `/admin/taswia/view-client-to-settle/${taswiaId}/${partnerId}`
  );
};

export const viewClientCurrentCollectionApi = (
  taswiaId,
  partnerId,
  page = 1,
  pageParam = "page"
) => {
  return axios.get(
    `/admin/taswia/client-current-collection/${taswiaId}/${partnerId}`,
    {
      params: { [pageParam]: page },
    }
  );
};

export const confirmTaswiaApi = (taswiaId, partnerId) => {
  return axios.get(`/admin/taswia/confirm/${taswiaId}/${partnerId}`);
};

export const viewSettlementSummaryApi = (taswiaId) => {
  return axios.get(`/admin/taswia/settlement-summery/${taswiaId}`);
};

export const endTaswiaApi = (id, notes, confirm) => {
  return axios.post(`/admin/taswia/end/${id}`, {
    notes: notes,
    confirm: confirm,
  });
};

export const viewPartnerSettlementDetailsApi = (
  taswiaId,
  partnerId,
  page = 1,
  pageParam = "page"
) => {
  return axios.get(
    `/admin/taswia/partner-settlement-details/${taswiaId}/${partnerId}`,
    {
      params: { [pageParam]: page },
    }
  );
};

export const viewSettlementHistoryApi = (taswiaId, page = 1) => {
  return axios.get(`/admin/taswia/finished-taswia-history/${taswiaId}`, {
    params: { page },
  });
};

// Replace eMoney

export const earningWalletApi = (id) => {
  return axios.get(`/admin/replace/emoney/earning-wallet/${id}`);
};

export function pendingEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/pending`, {
    params: { search: searchTerm, page },
  });
}

export function acceptedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/accepted`, {
    params: { search: searchTerm, page },
  });
}

export function rejectedEMoneyRequestsApi(searchTerm = "", page = 1) {
  return axios.get(`/admin/replace/emoney/rejected`, {
    params: { search: searchTerm, page },
  });
}

export const viewEMoneyDetailsApi = (id) => {
  return axios.get(`/admin/replace/emoney/details/${id}`);
};

export const acceptEMoneyRequestApi = (id) => {
  return axios.post(`/admin/replace/emoney/accept/${id}`);
};

export const rejectEMoneyRequestApi = (id) => {
  return axios.post(`/admin/replace/emoney/reject/${id}`);
};

// partner replace emoney

export const replaceEmoneyRequestApi = (formData) => {
  return axios.post(`/client/replace/emoney`, formData);
};

export const viewEarningWalletApi = () => {
  return axios.get(`/client/replace/emoney/earning-wallet`);
};

export function viewAllReplacementReqsApi(searchTerm = "", page = 1) {
  return axios.get(`/client/replace/emoney/list`, {
    params: { search: searchTerm, page },
  });
}

export const viewEmoneyTransferTypesApi = () => {
  return axios.get(`/client/emoney-transfer/types`);
};

export const searchTransferTo = async (userSearch) => {
  try {
    const response = await axios.get(
      `/client/emoney-transfer/search/${userSearch}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error searching for user:", error);
    return [];
  }
};

export const checkWalletBalance = async (transferValue) => {
  try {
    const response = await axios.get(
      `/client/emoney-transfer/check-wallet/${transferValue}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error checking wallet balance:", error);
    return false;
  }
};

export const submitTransfer = async (values) => {
  try {
    const response = await axios.post("/client/emoney-transfer/different-wallets", {
      transfer_to: values.transfer_to,
      transfer_value: values.transfer_value,
      notes: values.notes,
      confirm: values.confirm ? 1 : 0,
      transfer_type: values.transfer_type,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting transfer:", error);
    throw error;
  }
};

// Announcements APIS

export function announcementsListApi(page = 1, sortBy, sortDir) {
  return axios.get(`/admin/announcement/list`, {
    params: {
      page,
      sort_by: sortBy,
      sort_dir: sortDir,
    },
  });
}

export const createAnnouncement = async (announcementData) => {
  try {
    const formData = new FormData();

    // Add text fields to FormData
    formData.append("announcement_title", announcementData.announcement_title);
    formData.append(
      "announcement_details",
      announcementData.announcement_details
    );
    formData.append("link_url", announcementData.link_url || "");
    formData.append("on_portal", announcementData.on_portal ? 1 : 0);
    formData.append("schedule_date", announcementData.schedule_date);
    formData.append("announce_for", announcementData.announce_for);
    formData.append("link_text", announcementData.link_text || "");

    // Add file to FormData
    if (announcementData.image) {
      formData.append("image", announcementData.image);
    }

    const response = await axios.post("/admin/announcement/store", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Announcement stored successfully:", response.data);
    return response;
  } catch (error) {
    console.error("Error storing announcement:", error);
    throw error;
  }
};

export const updateAnnouncement = async (id, formData) => {
  try {
    const response = await axios.post(
      `/admin/announcement/update/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Announcement updated successfully:", response.data);
    return response;
  } catch (error) {
    console.error("Error updating announcement:", error);
    throw error;
  }
};

export const announceForListApi = () => {
  return axios.get(`/admin/announcement/announce-for-dropdown`);
};

export const AnnouncementLinkKeywordApi = () => {
  return axios.get(`/admin/announcement/link-keyword-dropdown`);
};

export const announcementDetailstApi = (id) => {
  return axios.get(`/admin/announcement/details/${id}`);
};
