import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../Redux/Auth/AuthActions";
import { debounce } from "lodash";
import {
  checkWalletBalance,
  searchTransferTo,
  submitTransfer,
  viewEarningWalletApi,
  viewEmoneyTransferTypesApi,
} from "../../apis";

const TransferEMoney = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [walletCheck, setWalletCheck] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [transferTypes, setTransferTypes] = useState([]);
  const [selectedTransferType, setSelectedTransferType] = useState(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const walletBalance = useSelector((state) => state.Auth.walletBalance);
  const [userEarningWallet, setUserEarningWallet] = useState();

  useEffect(() => {
    dispatch(fetchWalletBalance());
    getEmoneyTransferTypes();
    fetchEarningWallet();
  }, []);

  const fetchEarningWallet = async () => {
    try {
      const response = await viewEarningWalletApi();
      setUserEarningWallet(response.data.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    }
  };

  const handleTransferToChange = debounce(async (value) => {
    if (value.length > 0) {
      try {
        const results = await searchTransferTo(value);
        setSearchResults(results || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  }, 500);

  const handleTransferValueChange = async (value, setFieldValue) => {
    let isBalanceSufficient = true;

    if (value) {
      const transferValue = Number(value);

      if (selectedTransferType === 3 || selectedTransferType === 10) {
        isBalanceSufficient = await checkWalletBalance(transferValue);
      } else if (selectedTransferType === 9 || selectedTransferType === 11) {
        const earningWalletBalance = Number(userEarningWallet);
        isBalanceSufficient = transferValue <= earningWalletBalance;
      }
    }

    setWalletCheck(isBalanceSufficient);
    setFieldValue("transfer_value", value);
  };

  const loggedInUserClientId = useSelector(
    (state) => state?.Auth?.loggedInUser?.data?.model?.id
  );

  const getEmoneyTransferTypes = async () => {
    try {
      const response = await viewEmoneyTransferTypesApi();
      setTransferTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching transfer types:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await submitTransfer(values);
      Swal.fire({
        title: "Success",
        text: "E-Money Has Been Successfully Transferred",
        icon: "success",
      });
      dispatch(fetchWalletBalance());
      fetchEarningWallet();
      resetForm();
      setSelectedName("");
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "There was an error sending the request",
        icon: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const dynamicValidationSchema = () => {
    let schema = {
      transfer_type: Yup.string().required("Transfer type is required"),
      confirm: Yup.boolean().oneOf([true], "Confirmation is required"),
    };

    if (selectedTransferType === 10 || selectedTransferType === 11) {
      schema = {
        ...schema,
        transfer_to: Yup.boolean().required("Required"),
        transfer_value: Yup.number()
          .required("Required")
          .positive("Must be a positive number"),
        notes: Yup.string().required("transfer reason are required"),
      };
    } else {
      schema = {
        ...schema,
        transfer_to: Yup.string().required("Required"),
        transfer_value: Yup.number()
          .required("Required")
          .positive("Must be a positive number"),
        notes: Yup.string().notRequired(),
      };
    }

    return Yup.object().shape(schema);
  };

  return (
    <div className="tw-flex-col gap-6">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h4 className="card-title mb-0">Transfer E-Money</h4>
          <div className="tw-flex tw-gap-6">
            <h5 className="tw-text-base tw-font-medium mb-0">
              Earning Wallet:{" "}
              <span className="text-muted">{userEarningWallet} e.m</span>
            </h5>
            <h5 className="tw-text-base tw-font-medium mb-0">
              Registration Wallet:{" "}
              <span className="text-muted">{walletBalance} e.m</span>
            </h5>
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={{
              transfer_type: "",
              transfer_to: "",
              transfer_value: "",
              notes: "",
              confirm: false,
            }}
            validationSchema={dynamicValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="tw-mb-6">
                    <h3 className="tw-text-lg tw-font-semibold tw-mb-2">
                      Select Transfer Type:
                    </h3>
                    <div className="tw-grid tw-grid-cols-1 tw-mb-6">
                      {transferTypes.map((type) => (
                        <label
                          key={type.id}
                          className="tw-flex tw-items-center tw-gap-3 tw-bg-white tw-shadow-md tw-p-3 tw-rounded-md tw-cursor-pointer hover:tw-bg-gray-100"
                        >
                          <Field
                            type="radio"
                            name="transfer_type"
                            value={type.id}
                            checked={selectedTransferType === type.id}
                            onChange={() => {
                              setSelectedTransferType(type.id);
                              setFieldValue("transfer_type", type.id);
                              if (type.id === 10 || type.id === 11) {
                                setFieldValue(
                                  "transfer_to",
                                  loggedInUserClientId.toString()
                                );
                              } else {
                                setFieldValue("transfer_to", "");
                              }
                            }}
                            className="tw-h-5 tw-w-5 tw-text-blue-600"
                          />
                          <span className="tw-text-sm tw-font-medium">
                            {type.transaction_type}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {!!selectedTransferType && (
                    <>
                      {selectedTransferType !== 10 &&
                        selectedTransferType !== 11 && (
                          <div className="col-md-6 mb-3">
                            <label
                              className="form-label required-label"
                              htmlFor="transfer_to"
                            >
                              Transfer To
                            </label>
                            <Field
                              type="text"
                              name="transfer_to"
                              className={`form-control ${
                                touched.transfer_to && errors.transfer_to
                                  ? "custom-invalid-input"
                                  : ""
                              }`}
                              value={selectedName}
                              innerRef={inputRef}
                              onChange={(e) => {
                                const { value } = e.target;
                                setSelectedName(value);
                                handleTransferToChange(value);
                              }}
                            />
                            <ErrorMessage
                              name="transfer_to"
                              component="div"
                              className="invalid-feedback"
                            />
                            {searchResults.length > 0 && (
                              <ul
                                ref={dropdownRef}
                                className="tw-absolute tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg tw-shadow-lg tw-w-full tw-mt-2 tw-max-h-48 tw-overflow-y-auto tw-z-10"
                              >
                                {searchResults.map((result) => (
                                  <li key={result.id} className="tw-list-none">
                                    <button
                                      className="tw-w-full tw-text-left tw-px-4 tw-py-2 tw-cursor-pointer tw-hover:bg-gray-100"
                                      onClick={() => {
                                        setFieldValue("transfer_to", result.id);
                                        setSelectedName(
                                          `${result.name} (${result.referral_code})`
                                        );
                                        setSearchResults([]);
                                      }}
                                    >
                                      {result.name} ({result.referral_code})
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )}

                      <div className="col-md-6 mb-3">
                        <label
                          className="form-label required-label"
                          htmlFor="transfer_value"
                        >
                          Transfer Value (EM)
                        </label>
                        <Field
                          type="text"
                          name="transfer_value"
                          className={`form-control ${
                            touched.transfer_value && errors.transfer_value
                              ? "custom-invalid-input"
                              : ""
                          }`}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleTransferValueChange(value, setFieldValue);
                          }}
                        />

                        <ErrorMessage
                          name="transfer_value"
                          component="div"
                          className="invalid-feedback"
                        />
                        {walletCheck === false && (
                          <div className="text-danger">
                            {selectedTransferType === 9
                              ? "Insufficient Earning Wallet balance."
                              : "Insufficient Registration Wallet balance."}
                          </div>
                        )}
                      </div>

                      <div className="col-md-12">
                        <div className="col-md-6 mb-3 !tw-p-0">
                          <label
                            className="form-label required-label"
                            htmlFor="notes"
                          >
                            Transfer reason
                          </label>
                          <Field
                            as="textarea"
                            name="notes"
                            className="form-control"
                            rows="4"
                          />
                          <ErrorMessage
                            name="notes"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="form-check mb-3 tw-flex tw-items-end tw-w-full tw-ms-2">
                        <input
                          type="checkbox"
                          className={`form-check-input tw-h-5 tw-w-5 ${
                            touched.confirm && errors.confirm
                              ? "is-invalid"
                              : ""
                          }`}
                          id="confirmCheckbox"
                          name="confirm"
                          checked={values.confirm}
                          onChange={(e) =>
                            setFieldValue("confirm", e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label !tw-m-0 tw-ps-4 tw-w-full"
                          htmlFor="confirmCheckbox"
                        >
                          I confirm that the transfer process is correct, and I
                          can't cancel it.
                        </label>
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Transfer
                      </button>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TransferEMoney;
